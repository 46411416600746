<template>
  <div class="footer-menu">
    <div class="footer-menu__column">
      <div class="footer-menu__title">CONTACT INFO</div>

      <div>
        <strong>Phone: </strong>US
        <a href="tel:(952) 491-0586">(952) 491-0586</a>
      </div>
      <div>
        <strong>Email: </strong><a href="mailto:support@treqit.com">support@treqit.com</a>
      </div>
      <div>
        <strong>Location: </strong>3727 Joppa Ave, Saint Louis Park MN 55416
      </div>
    </div>

    <div class="footer-menu__column">
      <div class="footer-menu__title">Company</div>

      <NuxtLink to="/signup" class="d-block">About Us</NuxtLink>
      <!-- <router-link to="#" class="d-block">Legal Information</router-link> -->
      <NuxtLink to="/signup" class="d-block">Contact Us</NuxtLink>
    </div>

    <div class="footer-menu__column">
      <div class="footer-menu__title">HELP CENTER</div>

      <NuxtLink to="/signup" class="d-block">How to Host?</NuxtLink>
      <NuxtLink to="/signup" class="d-block">Why Us?</NuxtLink>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.footer-menu {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 0 80px;
  font-size: 20px;
  color: #3c464d;
  line-height: 30px;
  letter-spacing: 0.4px;
  font-weight: 400;

  a {
    color: #3c464d;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.footer-menu__column {
  max-width: 280px;
}

.footer-menu__title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.4px;
  color: #3c9273;
  white-space: nowrap;
  text-transform: uppercase;
  margin-bottom: 40px;
}

@media screen and (max-width: 1239px) {
  .footer-menu {
    gap: 0 60px;
    font-size: 18px;
  }

  .footer-menu__title {
    font-size: 18px;
  }
}

@media screen and (max-width: 899px) {
  .footer-menu {
    gap: 20px;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .footer-menu__title {
    margin-bottom: 10px;
  }
}
</style>
