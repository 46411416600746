<template>
  <div class="footer-copy">
    &copy; Treqit {{ new Date().getFullYear() }}. All Rights Reserved.
  </div>
</template>

<style lang="scss" scoped>
.footer-copy {
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  color: #9a9a9a;
  letter-spacing: 0.28px;
}
</style>
